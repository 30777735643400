import type { FontAwesomeIcon } from '@/types/common/fa';
import moment from 'moment-timezone';
import packageJSON from '../../package.json';

// Sync with <title> from index.html
export const SITE_NAME = 'Support';

export const IS_DEV = process.env.NODE_ENV === 'development';

// Track app version
export const APP_VERSION: string = packageJSON.version; // show version

// API base url
export const API_BASE_URL: string = process.env.REACT_APP_API_SUPPORT_URL || 'REACT_APP_API_SUPPORT_URL';
export const API_CUSTOMER_BASE_URL = process.env.REACT_APP_API_CUSTOMER_URL || 'REACT_APP_API_CUSTOMER_URL';
export const API_WRITER_BASE_URL = process.env.REACT_APP_API_WRITER_URL || 'REACT_APP_API_WRITER_URL';

export const WRITER_APP_URL: string = process.env.REACT_APP_WRITER_APP_URL || 'REACT_APP_WRITER_APP_URL';

// DATE ISO FORMAT
export const DATE_FORMAT_ISO = 'YYYY-MM-DD HH:mm:ss';
export const TIME_FORMAT = 'h:mm A';
export const DATE_FORMAT_DEFAULT = `MMM DD, ${TIME_FORMAT}`;
export const DATE_FORMAT_SHORT = 'MMM DD HH:mm';
export const ACTIVE_HOURS_FRONT_TIME_FORMAT = 'hh:mm A';
export const SERVER_TIME_FORMAT = 'HH:mm:ss';

export const PRIMARY_TIME_ZONES = [
  'EST5EDT',
  'CST6CDT',
  'MST7MDT',
  'PST8PDT',
  'MST',
  'Europe/Kiev',
  'Africa/Nairobi',
  'Europe/Berlin',
  'Europe/London',
  'Australia/Sydney',
];

export const IMAGE_EXTENSIONS = ['.jpeg', '.png', '.gif', '.jpg'];

export const ALLOWED_EXTENSIONS = [
  ...IMAGE_EXTENSIONS,
  '.txt',
  '.zip',
  '.doc',
  '.docx',
  '.xls',
  '.xlsx',
  '.rtf',
  '.ppt',
  '.pptx',
  '.csv',
  '.pdf',
  '.odt',
  '.html',
];

export const UPLOAD_ACCEPT_FILES = ALLOWED_EXTENSIONS.join(',');

export const UPLOAD_ACCEPT_IMAGES = IMAGE_EXTENSIONS.join(',');

export const ORDERFORM_CALC_DEBOUNCE = 500;

export const isMac = !!navigator.platform.match(/mac/gi);

export const FILE_CARD_SIZE = 'tiny';

export const USER_DATA_KEY: 'customer' | 'writer' | 'author' = 'author'; // for messenger

// ICONS
export const ICON_LOGS: FontAwesomeIcon = 'fa-rectangle-history-circle-user';

// The only difference between timezones on back-end and front-end
export const TIMEZONES = moment.tz.names().filter((tz) => !['Europe/Kyiv'].includes(tz));

export const DESCRIPTION_MAX_LENGTH = 12000;
export const NAME_MAX_LENGTH = 45;

export const SENTRY_DSN = 'https://d3e5b5bf696d41089628edb37fda25a2@o1028159.ingest.sentry.io/4504600095817728';
