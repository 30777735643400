import { MS_API_PREFIX, MS_PATH_PREFIX } from '@/addons/mailService/config/constants';
import { Roles } from '@/types/user';
import { FeatureItem } from '@/config/features';

export const MS_FEATURE_LIST = [
  'MSSite',
  'MSSiteAssociation',
  'MSMailAccount',
  'MSMailTrigger',
  'MSMailTriggerCall',
  'MSMailTemplate',
  'MSMailTemplateComponent',
  'MSMail',
  'MSFile',
] as const;

export type MSFeatureType = typeof MS_FEATURE_LIST[number];

type MSFeaturesDictionary = {
  [key in MSFeatureType]: FeatureItem;
};

export const MS_FEATURES: MSFeaturesDictionary = {
  // Mail service
  MSSite: {
    type: 'MSSite',
    title: 'Sites',
    path: `${MS_PATH_PREFIX}/sites`,
    apiPath: `${MS_API_PREFIX}/sites`,
    icon: 'fa-solar-system',
    roles: [Roles.ADMIN],
    category: 'hidden',
  },
  MSSiteAssociation: {
    type: 'MSSiteAssociation',
    title: 'Site associations',
    path: `${MS_PATH_PREFIX}/site-associations`,
    apiPath: `${MS_API_PREFIX}/sites/associations`,
    icon: 'fa-solar-system',
    roles: [Roles.ADMIN],
    category: 'hidden',
  },
  MSMailAccount: {
    type: 'MSMailAccount',
    title: 'Mail accounts',
    path: `${MS_PATH_PREFIX}/mail-accounts`,
    apiPath: `${MS_API_PREFIX}/sites/mails/accounts`,
    icon: 'fa-envelopes-bulk',
    roles: [Roles.ADMIN],
    category: 'hidden',
  },
  MSMailTrigger: {
    type: 'MSMailTrigger',
    title: 'Mail triggers',
    path: `${MS_PATH_PREFIX}/mail-triggers`,
    apiPath: `${MS_API_PREFIX}/sites/mails/triggers`,
    icon: 'fa-bullseye-pointer',
    roles: [Roles.ADMIN],
    category: 'hidden',
  },
  MSMailTriggerCall: {
    type: 'MSMailTriggerCall',
    title: 'Mail trigger calls',
    path: `${MS_PATH_PREFIX}/mail-triggers-calls`,
    apiPath: `${MS_API_PREFIX}/sites/mails/triggers/calls`,
    icon: 'fa-bullseye-arrow',
    roles: [Roles.ADMIN],
    category: 'hidden',
  },
  MSMailTemplateComponent: {
    type: 'MSMailTemplateComponent',
    title: 'Mail template components',
    path: `${MS_PATH_PREFIX}/template-components`,
    apiPath: `${MS_API_PREFIX}/sites/mails/templates/components`,
    icon: 'fa-shapes',
    roles: [Roles.ADMIN],
    category: 'hidden',
  },
  MSMailTemplate: {
    type: 'MSMailTemplate',
    title: 'Mail templates',
    path: `${MS_PATH_PREFIX}/templates`,
    apiPath: `${MS_API_PREFIX}/sites/mails/templates`,
    icon: 'fa-file-lines',
    roles: [Roles.ADMIN],
    category: 'hidden',
  },
  MSMail: {
    type: 'MSMail',
    title: 'Mails',
    path: `${MS_PATH_PREFIX}/mails`,
    apiPath: `${MS_API_PREFIX}/sites/mails`,
    icon: 'fa-envelope-open-text',
    roles: [Roles.ADMIN],
    category: 'hidden',
  },
  MSFile: {
    type: 'MSFile',
    title: 'Files',
    path: `${MS_PATH_PREFIX}/files`,
    apiPath: `${MS_API_PREFIX}/files`,
    icon: 'fa-files',
    roles: [Roles.ADMIN],
    category: 'hidden',
  },
};
