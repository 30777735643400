import { FeatureItem } from '@/config/features';
import { HR_FLOW_PATH_PREFIX as path } from '@/addons/hrFlow/config/constants';
import { RG_ADMINS } from '@/components/guards/RBAC/groups';

export const HR_FLOW_FEATURE_LIST = [
  'HRFlow', // main page
  'TestQuiz',
  'TestQuizQuestion',
  'TestQuizAnswer',
  'TestQuizUndertaking',
  'TestQuizUndertakingQuestion',
  'TestEssay',
  'TestEssayUndertaking',
  'TestEssayUndertakingItem',
] as const;

export type HRFlowFeatureType = typeof HR_FLOW_FEATURE_LIST[number];

type HrFlowFeaturesDictionary = {
  [key in HRFlowFeatureType]: FeatureItem;
};

export const HR_FLOW_FEATURES: HrFlowFeaturesDictionary = {
  HRFlow: {
    type: 'HRFlow',
    path,
    icon: 'fa-suitcase',
    title: 'HR Flow',
    category: 'hr',
    roles: RG_ADMINS,
  },

  // Test quiz
  TestQuiz: {
    type: 'TestQuiz',
    path: `${path}/test-quizzes`,
    apiPath: '/test-quizzes',
    icon: 'fa-tasks',
    title: 'Test quizzes',
    category: 'hidden',
  },
  TestQuizQuestion: {
    type: 'TestQuizQuestion',
    path: `${path}/test-quizzes/questions`,
    apiPath: '/test-quizzes/questions',
    icon: 'fa-question',
    title: 'Test quiz · Questions',
    category: 'hidden',
  },
  TestQuizAnswer: {
    type: 'TestQuizAnswer',
    path: `${path}/test-quizzes/answers`,
    apiPath: '/test-quizzes/answers',
    icon: 'fa-question',
    title: 'Test quiz · Answers',
    category: 'hidden',
  },
  TestQuizUndertaking: {
    type: 'TestQuizUndertaking',
    path: `${path}/test-quizzes/undertakings`,
    apiPath: '/test-quizzes/undertakings',
    icon: 'fa-stopwatch-20',
    title: 'Test quiz · Undertakings',
    category: 'hidden',
  },
  TestQuizUndertakingQuestion: {
    type: 'TestQuizUndertakingQuestion',
    path: `${path}/test-quizzes/undertakings/questions`,
    apiPath: '/test-quizzes/undertakings/questions',
    icon: 'fa-circle',
    title: 'Test quiz undertaking · Questions',
    category: 'hidden',
  },

  // Test essay
  TestEssay: {
    type: 'TestEssay',
    apiPath: '/test-essays',
    path: `${path}/test-essays`,
    icon: 'fa-file-signature',
    title: 'Test essay',
    category: 'hidden',
  },
  TestEssayUndertaking: {
    type: 'TestEssayUndertaking',
    apiPath: '/test-essays/undertakings',
    path: `${path}/test-essays/undertakings`,
    icon: 'fa-stopwatch-20',
    title: 'Test essay · Undertakings',
    category: 'hidden',
  },
  TestEssayUndertakingItem: {
    type: 'TestEssayUndertakingItem',
    apiPath: '/test-essays/undertakings/items',
    path: `${path}/test-essays/undertakings/items`,
    icon: 'fa-file-certificate',
    title: 'Test essay · Items',
    category: 'hidden',
  },
};
