import { Roles } from '@/types/user';

/**
 * RG (ROLE GROUPS)
 */

/**
 * ROLE GROUPS (cascades).
 * Each next group extends previous.
 */
export const RG_SUPER_ADMIN = [Roles.ADMIN];
export const RG_ADMINS = [Roles.ADMIN, Roles.SEMI_ADMIN];
// Extra
export const RG_EXTRA = [...RG_ADMINS, Roles.QUALITY, Roles.SALES, Roles.RISK, Roles.WRITER_CONTROL];
export const RG_SUPERVISORS = [...RG_ADMINS, Roles.WS_SUPERVISOR, Roles.CS_SUPERVISOR];
export const RG_CS_SUPERVISOR = [...RG_ADMINS, Roles.CS_SUPERVISOR];
export const RG_WS_SUPERVISOR = [...RG_ADMINS, Roles.WS_SUPERVISOR];
export const RG_MANAGERS = [...RG_SUPERVISORS, Roles.WRITER_CONTROL];
// GRADINGS
export const RG_SENIORS = [...RG_SUPERVISORS, Roles.CS_SENIOR, Roles.WS_SENIOR, ...RG_EXTRA];
export const RG_CS_SENIOR = [...RG_ADMINS, Roles.CS_SUPERVISOR, Roles.CS_SENIOR];
export const RG_WS_SENIOR = [...RG_ADMINS, Roles.WS_SUPERVISOR, Roles.WS_SENIOR];
export const RG_MIDDLES = [...RG_SENIORS, Roles.CS_MIDDLE, Roles.WS_MIDDLE, Roles.QUALITY];
export const RG_CS_MIDDLE = [...RG_CS_SENIOR, Roles.CS_MIDDLE];
export const RG_WS_MIDDLE = [...RG_WS_SENIOR, Roles.WS_MIDDLE];
export const ROLE_GROUP_WS_MIDDLE = [...RG_WS_SENIOR, Roles.WS_MIDDLE];
export const RG_STRONG_JUNIORS = [...RG_MIDDLES, Roles.CS_STRONG_JUNIOR, Roles.WS_STRONG_JUNIOR];
export const RG_CS_STRONG_JUNIOR = [...RG_CS_MIDDLE, Roles.CS_STRONG_JUNIOR];
export const RG_WS_STRONG_JUNIOR = [...ROLE_GROUP_WS_MIDDLE, Roles.WS_STRONG_JUNIOR];
export const RG_JUNIORS = [...RG_STRONG_JUNIORS, ...RG_EXTRA, Roles.CS_JUNIOR, Roles.WS_JUNIOR];
export const RG_CS_JUNIOR = [...RG_CS_STRONG_JUNIOR, Roles.CS_JUNIOR];
export const RG_WS_JUNIOR = [...RG_WS_STRONG_JUNIOR, Roles.WS_JUNIOR];
export const RG_WRITER_CONTROL = [...RG_ADMINS, Roles.WS_SUPERVISOR, Roles.WRITER_CONTROL];
// All department (includes trainee)
export const RG_DEPARTMENT_CS = [
  ...RG_ADMINS,
  Roles.CS_TRAINEE,
  Roles.CS_JUNIOR,
  Roles.CS_STRONG_JUNIOR,
  Roles.CS_MIDDLE,
  Roles.CS_SENIOR,
  Roles.CS_SUPERVISOR,
];
export const RG_DEPARTMENT_WS = [
  ...RG_ADMINS,
  Roles.WS_TRAINEE,
  Roles.WS_JUNIOR,
  Roles.WS_STRONG_JUNIOR,
  Roles.WS_MIDDLE,
  Roles.WS_SENIOR,
  Roles.WS_SUPERVISOR,
  Roles.WRITER_CONTROL,
];
// Exclude trainee & paycore
export const RG_ADVANCED = [...RG_STRONG_JUNIORS, ...RG_EXTRA];
