// a function to retry loading a chunk to avoid chunk load error for out of date code
import { ComponentType, lazy, LazyExoticComponent } from 'react';

/**
 * https://www.codemzy.com/blog/fix-chunkloaderror-react
 *                              fn  fn      fn      fn     import
 *                              \/  \/      \/      \/      \/
 * const DiscountSearchPage = () => lazyRetry(() => import('...'), 'String'));
 *
 */

export const lazyRetry = <T extends ComponentType<any>>(
  componentImport: () => Promise<{ default: T }>,
  name: string,
): LazyExoticComponent<T> =>
  lazy(
    () =>
      new Promise<{ default: T }>((resolve, reject) => {
        // check if the window has already been refreshed
        const hasRefreshed = JSON.parse(window.sessionStorage.getItem(`retry-${name}-refreshed`) || 'false');

        // try to import the component
        componentImport()
          .then((component) => {
            window.sessionStorage.setItem(`retry-${name}-refreshed`, 'false'); // success so reset the refresh
            resolve(component);
          })
          .catch((error) => {
            if (!hasRefreshed) {
              // not been refreshed yet
              window.sessionStorage.setItem(`retry-${name}-refreshed`, 'true'); // we are now going to refresh
              return window.location.reload(); // refresh the page
            }
            reject(error); // Default error behaviour as already tried refresh
          });
      }),
  );
