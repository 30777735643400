import { useAuthLogoutMutation } from '@/api/auth';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { RootState } from '@/store/store';
import { authLogout } from '@/store/auth/authActions';

export const useAuth = (): RootState['auth'] => useAppSelector(({ auth }: RootState) => auth);

export const useGracefulLogout = () => {
  const [requestLogout] = useAuthLogoutMutation();
  const dispatch = useAppDispatch();

  const logoutFn = async () => {
    // logout with endpoint
    await requestLogout();
    dispatch(authLogout());
  };

  return {
    logout: logoutFn,
  };
};
