import { Patch } from '@/shared/types/api';
import { LogMessage } from '@/types/common/api';
import { UserAssociationStatuses } from '@/types/common/associations';
import { ServiceEntityStatuses } from '@/types/common/status';
import { Email } from '@/types/email';
import { Phone } from '@/types/phone';
import { AscDesc } from '@/types/search';
import { Log, LogDataField } from '@/types/common/log';

export enum Roles {
  ADMIN = 1,
  SEMI_ADMIN,

  CS_TRAINEE,
  WS_TRAINEE,

  CS_JUNIOR,
  WS_JUNIOR,

  CS_STRONG_JUNIOR,
  WS_STRONG_JUNIOR,

  CS_MIDDLE,
  WS_MIDDLE,

  CS_SENIOR,
  WS_SENIOR,

  CS_SUPERVISOR,
  WS_SUPERVISOR,

  SALES,
  RISK,
  QUALITY,
  WRITER_CONTROL,

  PAYCORE = 100,
}

/*
 * export enum UserPhoneStatuses {
 *   Claimed = 1,
 *   Assigned,
 *   Replaced,
 *   Abandoned,
 *   Removed,
 *   Reclaimed,
 *   Restored,
 * }
 */

/*
 * export enum UserEmailStatuses {
 *   Claimed = 1,
 *   Assigned,
 *   Replaced,
 *   Abandoned,
 *   Removed,
 *   Reclaimed,
 *   Restored,
 * }
 */

/*
 * export enum UserStatuses {
 *   Draft = 1,
 *   Active,
 *   Paused,
 *   Removed,
 * }
 */

export type User = {
  id: number;
  dateAdded: string;
  firstName?: string;
  lastName?: string;
  publicName?: string;
  timezone?: string;
  idStatus: ServiceEntityStatuses;
  idRole: Roles;
  primaryEmail?: Email;
  primaryPhone?: Phone;
  dateUpdated?: string;
  dateLastActive?: string;
};

export type UserSearchItem = User & {
  author?: User;
};

export type UserDetails = UserSearchItem;

export type UserEmailAssociation = {
  dateAdded: string;
  dateRemoved?: string;
  dateConfirmed?: string;
  dateRestored?: string;
  idStatus: UserAssociationStatuses;
  user: User;
};

export type UserPhoneAssociation = UserEmailAssociation;

export type UserSearchParams = {
  dateAddedFrom?: string;
  dateAddedTo?: string;
  name?: string;
  idRole?: Roles[];
  idStatus?: ServiceEntityStatuses[];
  idAuthor?: number[];
  idEmail?: number[];
  sortType?: AscDesc;
  sortBy?: UserSortField;
  page?: number;
  size?: number;
};

export type UserCreateRequest = {
  firstName: string;
  lastName: string;
  publicName?: string;
  email: string;
  password: string;
  idRole: Roles;
};

export type UserUpdatePayload = LogMessage & {
  firstName?: string;
  lastName?: string;
  publicName?: string;
  idRole?: Roles;
};

export type UserUpdateRequest = Patch<UserUpdatePayload>;

export type UserUpdatePasswordPayload = LogMessage & {
  password: string;
};

export type UserUpdatePasswordRequest = Patch<UserUpdatePasswordPayload>;

export type UserToggleStatusRequest = Patch<LogMessage>;

export type UserSortField =
  | 'id'
  | 'dateAdded'
  | 'firstName'
  | 'lastName'
  | 'publicName'
  | 'email'
  | 'idRole'
  | 'idStatus'
  | 'idAuthor'
  | 'idEmail';

export type UserDateField = 'dateAdded';

type UserLogData = LogDataField<'firstName', string> &
  LogDataField<'lastName', string> &
  LogDataField<'publicName', string> &
  LogDataField<'passwordHash', string> &
  LogDataField<'publicEmail', string> &
  LogDataField<'preferences', object> &
  LogDataField<'timezone', string> &
  LogDataField<'idRole', Roles> &
  LogDataField<'idStatus', ServiceEntityStatuses>;

export type UserLog = Log<UserLogData>;
