import { Button } from '@/components/common/button/Button';
import { SpinFullscreen } from '@/components/common/spinner/SpinFullscreen';
import { useTrackBreakpoint } from '@/hooks/breakpoints/useTrackBreakpoint';
import { msg } from '@/shared/helpers/msg';
import { useAppVersion } from '@/shared/hooks/useAppVersion';
import { useAuth } from '@/store/auth/authHooks';
import { TWindowBreakpoint } from '@/types/breakpoints';
import React, { createContext, ReactElement, Suspense, useContext, useEffect } from 'react';
import { lazyRetry as _ } from '@/components/common/errors/lazyRetry';

// Main views
const Dashboard = _(() => import('@/components/Dashboard'), 'Dashboard');
const LoginPage = _(() => import('@/components/features/auth/LoginPage'), 'LoginPage');

export type TAppContext = { breakpoint: TWindowBreakpoint };
export const AppContext = createContext<TAppContext>({ breakpoint: 'xxl' });
export const useAppContext = () => useContext(AppContext);

export default function App(): ReactElement {
  const { isLoggedIn } = useAuth();

  const breakpoint = useTrackBreakpoint();

  const { reloadRequired } = useAppVersion();

  useEffect(() => {
    document.title = 'Loading...';
  }, []);

  useEffect(() => {
    if (!reloadRequired) return;
    msg.warn({
      duration: 0,
      content: (
        <div
          style={{
            display: 'inline-block',
            textAlign: 'left',
            verticalAlign: 'top',
          }}
        >
          Application has been updated.{' '}
          <Button onClick={() => window.location.reload()} type="primary" size="small">
            Reload page
          </Button>
        </div>
      ),
    });
  }, [reloadRequired]);

  return (
    <AppContext.Provider value={{ breakpoint }}>
      <Suspense fallback={<SpinFullscreen />}>{isLoggedIn ? <Dashboard /> : <LoginPage />}</Suspense>
    </AppContext.Provider>
  );
}
