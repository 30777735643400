import { ITokenPair } from '@/shared/types/auth';
import { RootState } from '@/store/store';
import { User } from '@/types/user';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface AuthState {
  isLoggedIn: boolean;
  user: User;
  tokens: ITokenPair;
}

/*
 * TODO: Update with profile
 * auth.user - for websockets (imperative code -> only getState)
 */

const userInitialState = {
  id: 0,
  dateAdded: '',
  firstName: '',
  lastName: '',
  idStatus: 0,
  idRole: 0,
  email: {
    id: 0,
    dateAdded: '',
    address: '',
    idStatus: 0,
  },
};

export const authInitialState: AuthState = {
  isLoggedIn: false,
  user: userInitialState,
  tokens: {
    refreshToken: '',
    accessToken: '',
  },
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: authInitialState,
  reducers: {
    setUser: (state: AuthState, { payload }: PayloadAction<User | undefined>) => {
      state.user = payload?.id ? payload : userInitialState;
    },
    setTokens: (state: AuthState, { payload }: PayloadAction<ITokenPair>) => {
      state.tokens = payload;
      state.isLoggedIn = true;
    },
    refreshAccessToken: (state: AuthState, { payload }: PayloadAction<{ accessToken: string }>) => {
      state.tokens.accessToken = payload.accessToken;
    },
    logout: () => authInitialState,
  },
});

export default authSlice.reducer;

export const { setTokens, refreshAccessToken, logout, setUser } = authSlice.actions;

// Selectors
export const selectAuth = ({ auth }: RootState) => auth;
export const selectIsLoggedIn = ({ auth }: RootState) => auth.isLoggedIn;
export const selectUser = ({ auth }: RootState) => auth.user;
export const selectUserId = ({ auth }: RootState) => auth.user.id;
export const selectUserRole = ({ auth }: RootState) => auth.user.idRole;
