import { MS_API_PREFIX } from '@/addons/mailService/config/constants';
import { SCHEDULER_API_URL } from '@/addons/scheduler/config/constants';
import { FetchArgs } from '@reduxjs/toolkit/dist/query/react';
import { IErrorResponse } from '@/shared/types/api';
import { MSErrorResponse } from '@/addons/mailService/types/common';

import { omit, upperFirst } from 'lodash';

const adjustSSchedulerParams = (params?: Record<string, any>) => {
  if (!params) return params;

  const adjustedParams: Record<string, any> = {};

  Object.keys(params).forEach((key) => {
    if (key === 'size') {
      adjustedParams.limit = params.size;
    }

    if (key === 'sortBy' && params.sortBy) {
      adjustedParams[`sortBy${upperFirst(params.sortBy)}`] = params.sortType;
    }
  });

  return { ...omit(params, 'size', 'sortType', 'sortBy'), ...adjustedParams };
};

const isAddonsApi = (args: string | FetchArgs) =>
  typeof args !== 'string' && (args.url.startsWith(String(MS_API_PREFIX)) || args.url.startsWith(String(SCHEDULER_API_URL)));

export const getNormalizedArgs = (args: string | FetchArgs) => {
  if (typeof args === 'string') return args;
  // params adjustment for addons
  if (isAddonsApi(args)) {
    args.params = adjustSSchedulerParams(args.params);
  }

  return args;
};

export const getErrorText = (args: string | FetchArgs, error?: IErrorResponse & MSErrorResponse) => {
  if (isAddonsApi(args)) {
    return JSON.stringify(error?.data?.errors || error?.data, null, 2);
  }

  return JSON.stringify(error?.data?.report, null, 2)
    ?.replace(/[",`():{}[\]]/g, '')
    ?.replace('...', 'any valid');
};
